import React from 'react'
import Link from 'next/link'
import { useRouter } from 'next/router'
import classnames from 'classnames'
import { ParsedUrlQuery } from 'querystring'

import styles from './SubDepartmentCards.module.css'

// These are the 5 most important sub departments per super department.
//
// In the future we should look this up from GraphQL, but for now they are just
// hard coded.
//
// Later when we move to Product Categories, we can source them from GraphQL
// because we keep a `productCount` attribute on product categories, so it should
// be easy to pick the most popular sub categories
const departmentSubsets = [
  {
    slug: 'fashion',
    children: [
      {
        name: 'Accessories',
        slug: 'accessories'
      },
      {
        name: 'Clothing',
        slug: 'clothing'
      },
      {
        name: 'Cosmetics',
        slug: 'cosmetics'
      },
      {
        name: '������̳',
        slug: 'fashion2'
      },
      {
        name: 'Fragrance',
        slug: 'fragrance'
      },
      {
        name: 'Textiles',
        slug: 'textiles'
      }
    ]
  },
  {
    slug: 'gifts',
    children: [
      {
        name: 'Accessories',
        slug: 'accessories'
      },
      {
        name: 'Handmade',
        slug: 'handmade'
      },
      {
        name: 'Candles',
        slug: 'candles'
      },
      {
        name: 'Cosmetics',
        slug: 'cosmetics'
      },
      {
        name: 'Fragrance',
        slug: 'fragrance'
      },
      {
        name: 'Soaps',
        slug: 'soaps'
      }
    ]
  },
  {
    slug: 'home',
    children: [
      {
        name: 'Homewares',
        slug: 'homewares'
      },
      {
        name: 'Décor',
        slug: 'decor'
      },
      {
        name: 'Handmade',
        slug: 'handmade'
      },
      {
        name: 'Candles',
        slug: 'candles'
      },
      {
        name: 'Art',
        slug: 'art'
      },
      {
        name: 'Stationery',
        slug: 'stationery'
      }
    ]
  },
  {
    slug: 'kids',
    children: [
      {
        name: 'Toys',
        slug: 'toys'
      },
      {
        name: 'Baby',
        slug: 'baby'
      },
      {
        name: 'Collectables',
        slug: 'collectables'
      },
      {
        name: 'Novelties',
        slug: 'novelties'
      },
      {
        name: 'Games',
        slug: 'games'
      },
      {
        name: 'Education',
        slug: 'education'
      }
    ]
  },
  {
    slug: 'kitchen',
    children: [
      {
        name: 'Gourmet food',
        slug: 'gourmet-food'
      },
      {
        name: 'Glassware',
        slug: 'glassware'
      },
      {
        name: 'Kitchen',
        slug: 'kitchen2'
      },
      {
        name: 'Utensils',
        slug: 'utensils'
      }
    ]
  },
  {
    slug: 'outdoors',
    children: [
      {
        name: 'Garden',
        slug: 'garden'
      },
      {
        name: 'Travel',
        slug: 'travel'
      },
      {
        name: 'Adventure',
        slug: 'adventure'
      },
      {
        name: 'Outdoor',
        slug: 'outdoor'
      }
    ]
  },
  {
    slug: 'seasonal',
    children: [
      {
        name: 'Christmas',
        slug: 'christmas'
      },
      {
        name: 'Easter',
        slug: 'easter'
      }
    ]
  }
]

const SubDepartmentCards = ({
  superDepartmentSlug,
  className
}: {
  superDepartmentSlug: string
  className?: string
}) => {
  const router = useRouter()
  const superDepartment = departmentSubsets.find(d => d.slug === superDepartmentSlug)

  const convertObjectToQueryString = (obj: ParsedUrlQuery): string => {
    return Object.entries(obj)
      .filter(([key]) => key !== 'superDepartmentSlug' && key !== 'subDepartmentSlug')
      .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value as string)}`)
      .join('&')
  }

  let queryStringParams = convertObjectToQueryString({ ...router.query })
  queryStringParams = `${queryStringParams ? `?${queryStringParams}` : ''}`

  return (
    <>
      {superDepartment && (
        <div className={classnames(styles.container, className)}>
          {superDepartment.children.map(subDepartment => (
            <Link
              href={`/shop/d/${superDepartmentSlug}/${subDepartment.slug}${queryStringParams}`}
              key={subDepartment.name}>
              <a className={styles.subDepartmentContainer}>
                <img
                  src={`/marketplace/department-covers/${subDepartment.slug}.jpg`}
                  alt={`Shop Wholesale ${subDepartment.name}`}
                  className={styles.subDepartmentImage}
                />
                <h3 className={styles.subDepartmentName}>{subDepartment.name}</h3>
              </a>
            </Link>
          ))}
        </div>
      )}
    </>
  )
}

export default SubDepartmentCards
